import { generateMonths, generateYears } from '@/utils/nendo'
import { ROLE } from './role'

export const STATUS_TYPE = {
    active: '有効',
    deactive: '無効',
}
export const PLAN_TYPE = {
    starter: 'スターター',
    basic: 'ベーシック',
    enterprize: 'エンタープライズ',
    trial: 'トライアル',
}

export const ALL_VALUE = { id: '', value: '--' }

// idが0の項目も画面に表示する
export const STATUS_OPTIONS = [
    { id: 1, value: STATUS_TYPE.active },
    { id: 0, value: STATUS_TYPE.deactive },
]

export const PLAN_OPTIONS = [
    { id: 1, value: PLAN_TYPE.starter },
    { id: 2, value: PLAN_TYPE.basic },
    { id: 3, value: PLAN_TYPE.enterprize },
    { id: 4, value: PLAN_TYPE.trial },
]

export const ROLE_OPTIONS = [
    { id: ROLE.sysAdmin, value: 'システム管理者' },
    { id: ROLE.admin, value: 'アカウント管理者' },
    { id: ROLE.general, value: '一般ユーザー' },
    { id: ROLE.viewer, value: '閲覧者' },
]

export const YEAR_OPTIONS = generateYears()

export const MONTH_OPTIONS = generateMonths()
