import { AccentButton } from '@/components/atoms/Button/AccentButton'
import ButtonRounded from '@/components/atoms/Button/ButtonRounded'
import ErrorText from '@/components/atoms/ErrorText'
import InputField from '@/components/atoms/InputField'
import SelectField from '@/components/atoms/SelectField'
import { MONTH_OPTIONS, PLAN_OPTIONS, ROLE_OPTIONS } from '@/constant/options'
import { useTypedForm } from '@/hooks/useTypedForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { companyStore } from '@/store/slices/companiesSlice'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Controller, SubmitHandler, useFieldArray } from 'react-hook-form'
import { useSelector } from 'react-redux'

interface TCreateCompanyForm {
    onSubmit: SubmitHandler<TAllFormValues['CreateCompany']>
    onClose: () => void
}

function CreateCompanyForm(props: TCreateCompanyForm) {
    const { error: storeError } = useSelector(companyStore)

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useTypedForm('CreateCompany')

    const {
        fields: usersFields,
        append: usersAppend,
        remove: usersRemove,
    } = useFieldArray({
        control,
        name: 'users',
    })
    const {
        fields: sitesFields,
        append: sitesAppend,
        remove: sitesRemove,
    } = useFieldArray({
        control,
        name: 'sites',
    })

    return (
        <form onSubmit={handleSubmit(props.onSubmit)} className="create-company-form">
            <div className="comapy-static-info px-100">
                <div className="mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <InputField
                                    {...rest}
                                    size="small"
                                    id="name"
                                    isRequired
                                    label="企業名称"
                                    widthlabel={120}
                                    error={!!errors.name?.message ?? false}
                                    helperText={errors.name?.message}
                                />
                            )
                        }}
                        name="name"
                        control={control}
                    />
                </div>
                <div className="flex-center mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="planId"
                                    label="プラン"
                                    error={!!errors.planId?.message ?? false}
                                    widthlabel={120}
                                    size="small"
                                    menuItems={PLAN_OPTIONS}
                                    isRequired
                                    helperText={errors.planId?.message}
                                />
                            )
                        }}
                        name="planId"
                        control={control}
                    />
                </div>
                <div className="flex-center mt-20">
                    <Controller
                        render={({ field: { ref, ...rest } }) => {
                            return (
                                <SelectField
                                    {...rest}
                                    id="startMonth"
                                    label="開始月"
                                    error={!!errors.startMonth?.message ?? false}
                                    widthlabel={120}
                                    size="small"
                                    menuItems={MONTH_OPTIONS}
                                    isRequired
                                    helperText={errors.startMonth?.message}
                                />
                            )
                        }}
                        name="startMonth"
                        control={control}
                    />
                </div>
            </div>
            <div className="user-wrapper mt-20 px-50">
                <div className="display-flex item-center">
                    <h4 className="width-100 ma-0 mr-20">ユーザー一覧</h4>
                    <AccentButton
                        disabled={usersFields.length >= 5}
                        startIcon={<AddRoundedIcon />}
                        onClick={() => usersAppend({ name: '', email: '', roleId: 2 })}
                    >
                        追加
                    </AccentButton>
                </div>
                {usersFields.length ? (
                    <div className="display-flex justify-space-btwn item-center mt-20" style={{ columnGap: 10 }}>
                        <span></span>
                        <span className="mr-60 fw-600 fz-14">名前</span>
                        <span className="mr-40 fw-600 fz-14">Eメール</span>
                        <span className="mr-50 fw-600 fz-14">権限</span>
                    </div>
                ) : null}
                {usersFields.map((field, i) => (
                    <div
                        key={field.id}
                        className="form-list-item display-flex item-center mt-10"
                        style={{ columnGap: 10 }}
                    >
                        <Button className="remove-btn cursor-pointer" color="inherit" onClick={() => usersRemove(i)}>
                            <CloseRoundedIcon />
                        </Button>
                        <span className="count ma-0 pa-5">{i + 1}</span>
                        <Box sx={{ width: 200 }}>
                            <Controller
                                name={`users.${i}.name`}
                                render={({ field: { ref, ...rest } }) => {
                                    return <InputField {...rest} size="small" />
                                }}
                            />
                        </Box>
                        <Box sx={{ width: 300 }}>
                            <Controller
                                name={`users.${i}.email`}
                                render={({ field: { ref, ...rest } }) => <InputField {...rest} size="small" />}
                            />
                        </Box>
                        <Box sx={{ width: 120 }}>
                            <Controller
                                name={`users.${i}.roleId`}
                                render={({ field: { ref, ...rest } }) => (
                                    <SelectField {...rest} size="small" menuItems={ROLE_OPTIONS.slice(1)} />
                                )}
                            />
                        </Box>
                    </div>
                ))}
            </div>
            <div className="sites-wrapper mt-20 px-50">
                <div className="display-flex item-center">
                    <h4 className="width-100 ma-0 mr-20">拠点一覧</h4>
                    <AccentButton
                        startIcon={<AddRoundedIcon />}
                        onClick={() => sitesAppend({ name: '' })}
                        disabled={sitesFields.length >= 10}
                    >
                        追加
                    </AccentButton>
                </div>
                <div>
                    {sitesFields.map((field, i) => (
                        <div
                            key={field.id}
                            className="form-list-item display-flex item-center mt-20"
                            style={{ columnGap: 10 }}
                        >
                            <Button
                                className="remove-btn cursor-pointer"
                                color="inherit"
                                onClick={() => sitesRemove(i)}
                            >
                                <CloseRoundedIcon />
                            </Button>
                            <span className="count ma-0 pa-5">{i + 1}</span>
                            <div className="full-width">
                                <Controller
                                    name={`sites.${i}.name`}
                                    render={({ field: { ref, ...rest } }) => {
                                        return <InputField {...rest} size="small" />
                                    }}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {storeError && <ErrorText text={storeError} className="mt-10 text-center" />}
            <div className="mt-40 mb-10 flex-center">
                <ButtonRounded className="company-form-btn" types="outline" onClick={props.onClose}>
                    キャンセル
                </ButtonRounded>
                <ButtonRounded className="company-form-btn ml-20" type="submit" types="filled">
                    保存
                </ButtonRounded>
            </div>
        </form>
    )
}

export default CreateCompanyForm
