import CustomModal from '@/components/atoms/CustomModal'
import EditSiteForm from '@/components/molecules/Form/EditSiteForm'
import { errors } from '@/constant/errors'
import ReactHookForm from '@/providers/ReactHookForm'
import { TAllFormValues } from '@/react-hook-form/types'
import { schemaEditSite } from '@/react-hook-form/validations'
import { AppDispatch } from '@/store'
import {
    companyStore,
    createSite,
    deleteSite,
    getSitesByOrganizationId,
    updateSite,
} from '@/store/slices/companiesSlice'
import { setMessage } from '@/store/slices/messageSlice'
import { AxiosError } from 'axios'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TModalCompany } from './ModalCreateCompany'

export default function ModalEditSite(props: TModalCompany) {
    const dispatch = useDispatch<AppDispatch>()
    const { listSites, organizationId, sitesTotal, reUpdateModal } = useSelector(companyStore)
    // state pagination
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    useEffect(() => {
        if (organizationId || organizationId === 0)
            dispatch(
                getSitesByOrganizationId({
                    id: organizationId,
                    options: {
                        search: searchTerm ? searchTerm : undefined,
                        offset: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                }),
            )
    }, [searchTerm, page, rowsPerPage, reUpdateModal])

    const handleClose = () => {
        props.setOpen(false)
        setSearchTerm('')
        setPage(0)
        setRowsPerPage(10)
    }

    const handleSubmit = async ({ sites }: TAllFormValues['EditSites']) => {
        const site = sites.find((s) => s.isChange)
        if (site && (organizationId || organizationId === 0)) {
            if (!site?.isNewSite && site.siteId) {
                const res = await dispatch(
                    updateSite({ id: site.siteId, siteUpdate: { name: site.name, status: site.status } }),
                )
                const error = res.payload as AxiosError
                if (error.response) {
                    dispatch(
                        setMessage({
                            message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                            type: 'error',
                        }),
                    )
                } else {
                    dispatch(setMessage({ message: '拠点の更新に成功しました！', type: 'success' }))
                }
            } else {
                const res = await dispatch(
                    createSite({
                        site: { name: site?.name },
                        organizationId,
                    }),
                )
                const error = res.payload as AxiosError
                if (error.response) {
                    dispatch(
                        setMessage({
                            message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                            type: 'error',
                        }),
                    )
                } else {
                    dispatch(setMessage({ message: '拠点の作成に成功しました！', type: 'success' }))
                }
            }
        }
    }

    const handleDeleteSite = async (siteId: number) => {
        const res = await dispatch(deleteSite(siteId))
        const error = res.payload as AxiosError
        if (error.response) {
            dispatch(
                setMessage({
                    message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                    type: 'error',
                }),
            )
        } else {
            dispatch(setMessage({ message: '拠点の削除に成功しました！', type: 'success' }))
        }
    }

    const defaultValues = useMemo(
        () => ({
            sites: listSites.map((site) => ({ ...site, siteId: site.id })),
        }),
        [listSites],
    )

    return (
        <CustomModal width={800} title="拠点一覧" open={props.open} onClose={handleClose}>
            <>
                <ReactHookForm validateSchema={schemaEditSite} defaultValues={defaultValues}>
                    <EditSiteForm
                        onDelete={handleDeleteSite}
                        onClose={handleClose}
                        onSubmit={handleSubmit}
                        pagination={{ page, setPage, rowsPerPage, setRowsPerPage, total: sitesTotal }}
                        defaultValues={defaultValues}
                    />
                </ReactHookForm>
            </>
        </CustomModal>
    )
}
