import CustomModal from '@/components/atoms/CustomModal'
import EditCompanyForm from '@/components/molecules/Form/EditCompanyForm'
import { errors } from '@/constant/errors'
import { OrganizationUpdate } from '@/openapi'
import ReactHookForm from '@/providers/ReactHookForm'
import { schemaCreateCompany } from '@/react-hook-form/validations'
import { AppDispatch } from '@/store'
import { companyStore, updateCompany } from '@/store/slices/companiesSlice'
import { setMessage } from '@/store/slices/messageSlice'
import { AxiosError } from 'axios'
import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TModalCompany } from './ModalCreateCompany'

export default function ModalEditCompany(props: TModalCompany) {
    const { organizationId, listCompanies } = useSelector(companyStore)
    const dispatch = useDispatch<AppDispatch>()

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleSubmit = async (data: OrganizationUpdate) => {
        if (organizationId || organizationId === 0) {
            const res = await dispatch(updateCompany({ id: organizationId, companyUpdate: data }))
            const error = res.payload as AxiosError
            if (error.response) {
                dispatch(
                    setMessage({
                        message: error.response.data?.errors[0].message || errors.ERROR_OCCURRED,
                        type: 'error',
                    }),
                )
            } else {
                dispatch(setMessage({ message: '企業の更新に成功しました！', type: 'success' }))
            }
        }
        handleClose()
    }

    const defaultValues = useMemo(() => {
        if (organizationId || organizationId === 0) {
            const company = listCompanies.find((company) => company.id === organizationId)
            return {
                name: company?.name || '',
                status: company?.status || company?.status === 0 ? company?.status : '',
                startMonth: company?.startMonth || '',
                planId: company?.planId || '',
            }
        }
        return {
            name: '',
            status: '',
            startMonth: '',
            planId: '',
        }
    }, [organizationId])

    return (
        <CustomModal width={800} title="企業情報編集" open={props.open} onClose={handleClose}>
            <ReactHookForm validateSchema={schemaCreateCompany} defaultValues={defaultValues}>
                <EditCompanyForm onClose={handleClose} onSubmit={handleSubmit} />
            </ReactHookForm>
        </CustomModal>
    )
}
