import Line from '@/components/atoms/Line'
import SesionTableCompanies from '@/components/molecules/SessionTable/companies'
import CompaniesTable from '@/components/molecules/tables/companies'
import ModalCreateCompany from '@/components/organisms/Modals/ModalCreateCompany'
import ModalEditCompany from '@/components/organisms/Modals/ModalEditCompany'
import ModalEditSite from '@/components/organisms/Modals/ModalEditSite'
import ModalEditUser from '@/components/organisms/Modals/ModalEditUser'
import { withLayout } from '@/components/template/Layout'
import useDebounce from '@/hooks/useDebounce'
import { AppDispatch } from '@/store'
import {
    companyStore,
    getListCompanies,
    getSitesByOrganizationId,
    getUsersByOrganizationId,
    setOrganizationId,
} from '@/store/slices/companiesSlice'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function Companies() {
    // state session table
    const [status, setStatus] = useState<string | number>('')
    const [plan, setPlan] = useState<string | number>('')
    // state pagination
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    // state modal
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [showEditSiteModal, setShowEditSiteModal] = useState(false)
    const [showEditUserModal, setShowEditUserModal] = useState(false)
    const [showEditCompanyModal, setShowEditCompanyModal] = useState(false)

    const dispatch = useDispatch<AppDispatch>()
    const { listCompanies, total, loading, reUpdate } = useSelector(companyStore)
    const debouncedSearchTerm = useDebounce<string>(searchTerm, 1000)

    useEffect(() => {
        dispatch(
            getListCompanies({
                search: debouncedSearchTerm ? debouncedSearchTerm : undefined,
                planId: plan ? Number(plan) : undefined,
                status: status || status === 0 ? Number(status) : undefined,
                offset: page * rowsPerPage,
                limit: rowsPerPage,
            }),
        )
    }, [debouncedSearchTerm, plan, status, page, rowsPerPage, reUpdate])

    const handleCreateCompany = () => setShowCreateModal(true)
    const onShowSiteModal = async (id: number) => {
        await dispatch(getSitesByOrganizationId({ id }))
        setShowEditSiteModal(true)
    }
    const onShowUserModal = async (id: number) => {
        await dispatch(getUsersByOrganizationId({ id }))
        setShowEditUserModal(true)
    }
    const onShowCompanyModal = (id: number) => {
        dispatch(setOrganizationId(id))
        setShowEditCompanyModal(true)
    }

    return (
        <div className="companies-management-wrapper px-40">
            <h3 className="main-header">企業一覧</h3>
            <Line />
            <SesionTableCompanies
                status={status}
                setStatus={setStatus}
                plan={plan}
                setPlan={setPlan}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                onCreateCompany={handleCreateCompany}
                setPage={setPage}
            />
            <div className="company-content mt-40">
                <div className="m-auto-0">
                    <CompaniesTable
                        data={listCompanies}
                        loading={loading}
                        pagination={{ page, setPage, rowsPerPage, setRowsPerPage, total }}
                        onShowSiteModal={onShowSiteModal}
                        onShowUserModal={onShowUserModal}
                        onShowCompanyModal={onShowCompanyModal}
                    />
                </div>
            </div>
            {showCreateModal && <ModalCreateCompany open={showCreateModal} setOpen={setShowCreateModal} />}
            {showEditSiteModal && <ModalEditSite open={showEditSiteModal} setOpen={setShowEditSiteModal} />}
            {showEditUserModal && <ModalEditUser open={showEditUserModal} setOpen={setShowEditUserModal} />}
            {showEditCompanyModal && <ModalEditCompany open={showEditCompanyModal} setOpen={setShowEditCompanyModal} />}
        </div>
    )
}
export default withLayout(Companies)
