import theme from '@/theme'
import { TablePagination } from '@mui/material'
import React from 'react'

export interface TPagination {
    page: number
    setPage: React.Dispatch<React.SetStateAction<number>>
    rowsPerPage: number
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
    total: number
}

export default function CustomPagination(props: TPagination) {
    const handleChangePage = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        props.setPage(newPage)
    }
    const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.setRowsPerPage(parseInt(e.target.value, 10))
        props.setPage(0)
    }
    return (
        <TablePagination
            sx={{
                '& p': { fontFamily: theme.typography.fontFamily },
                '& .MuiSelect-select': { fontFamily: theme.typography.fontFamily },
            }}
            component="div"
            count={props.total}
            page={props.page}
            onPageChange={handleChangePage}
            rowsPerPage={props.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
            labelRowsPerPage={'ページあたりの行数：'}
            labelDisplayedRows={({ from, to, count, page }) =>
                `${count !== -1 ? count : `more than ${to}`} の ${from}–${to}`
            }
        />
    )
}
