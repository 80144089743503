import { AccentButton } from '@/components/atoms/Button/AccentButton'
import ButtonRounded from '@/components/atoms/Button/ButtonRounded'
import InputField from '@/components/atoms/InputField'
import NoRowsOverlay from '@/components/atoms/NoRowOverlay'
import SelectField from '@/components/atoms/SelectField'
import { STATUS_OPTIONS } from '@/constant/options'
import { useTypedForm } from '@/hooks/useTypedForm'
import { Site } from '@/openapi'
import { TAllFormValues, TSite, TSites } from '@/react-hook-form/types'
import { CloseRounded } from '@mui/icons-material'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useFieldArray } from 'react-hook-form'
import { UseFieldArrayUpdate } from 'react-hook-form/dist/types'
import AlertDialogSlide from '../Dialog/confirm'
import CustomPagination, { TPagination } from '../Pagination'

interface TCreateCompanyForm {
    onClose: () => void
    onSubmit: SubmitHandler<TAllFormValues['EditSites']>
    onDelete: (id: number) => void
    pagination: TPagination
    defaultValues: { sites: Site[] }
}

export default function EditSiteForm(props: TCreateCompanyForm) {
    const [itemEditing, setItemEditing] = useState<number | null>(null)
    const [showConfirm, setShowConfirm] = useState<boolean>(false)
    const [site, setSite] = useState<TSite | null>(null)

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        getValues,
        setError,
    } = useTypedForm('EditSites')

    useEffect(() => {
        reset(props.defaultValues)
    }, [props.defaultValues])

    const { fields, append, update } = useFieldArray({
        control,
        name: 'sites',
    })

    const handleEditClick = (i: number, fieldValue: TSite, update: UseFieldArrayUpdate<TSites, 'sites'>) => {
        const value = getValues(`sites.${i}.name`)
        if (!value) return setError(`sites.${i}.name`, { message: '空白にすることはできません' })
        else if (itemEditing || itemEditing === 0) {
            setItemEditing(null)
        } else {
            update(i, { ...fieldValue, isChange: true })
            setItemEditing(i)
        }
    }

    return (
        <form className="px-50" onSubmit={handleSubmit(props.onSubmit)}>
            <AlertDialogSlide
                title="本当にこの拠点を削除しますか?"
                open={showConfirm}
                setOpen={setShowConfirm}
                onOk={() => {
                    if (site?.siteId) props.onDelete(site?.siteId)
                    setShowConfirm(false)
                }}
            >
                拠点: {site?.name}
            </AlertDialogSlide>
            <div className="mt-40">
                <AccentButton
                    startIcon={<AddRoundedIcon />}
                    onClick={() => {
                        append({ name: '', status: 1, isChange: true, isNewSite: true })
                        setItemEditing(fields.length)
                    }}
                    disabled={itemEditing !== null}
                >
                    追加
                </AccentButton>
            </div>
            <div className="mt-20">
                {fields.length ? (
                    fields.map((field, i) => (
                        <div
                            key={field.id}
                            className="form-list-item display-flex item-center mt-10"
                            style={{ columnGap: 15 }}
                        >
                            <Button
                                className="remove-btn cursor-pointer"
                                onClick={() => {
                                    setShowConfirm(true)
                                    setSite({ ...field })
                                }}
                                color="inherit"
                                disabled={itemEditing !== null || (errors['sites'] && !errors['sites'].length)}
                            >
                                <CloseRounded sx={{ margin: '5px 0 0' }} />
                            </Button>
                            <span className="count ma-0">{i + 1}</span>
                            <Box sx={{ width: 500 }}>
                                <Controller
                                    name={`sites.${i}.name`}
                                    render={({ field: { ref, ...rest } }) => {
                                        return (
                                            <InputField
                                                {...rest}
                                                size="small"
                                                InputProps={{
                                                    readOnly: itemEditing !== i,
                                                }}
                                                error={!!(errors['sites'] && errors['sites'][i]?.name?.message)}
                                                helperText={errors['sites'] && errors['sites'][i]?.name?.message}
                                            />
                                        )
                                    }}
                                />
                            </Box>
                            <Box sx={{ width: 150 }}>
                                <Controller
                                    name={`sites.${i}.status`}
                                    render={({ field: { ref, ...rest } }) => (
                                        <SelectField
                                            {...rest}
                                            className={itemEditing === i ? '' : 'pointer-event-none'}
                                            size="small"
                                            menuItems={STATUS_OPTIONS}
                                            helperText={
                                                errors['sites'] && errors['sites'][i]?.name?.message ? ' ' : null
                                            }
                                        />
                                    )}
                                />
                            </Box>
                            <Button
                                onClick={() => handleEditClick(i, field, update)}
                                color={
                                    itemEditing === i || (errors['sites'] && errors['sites'][i]) ? 'primary' : 'inherit'
                                }
                                disabled={
                                    (itemEditing !== null && itemEditing !== i) ||
                                    (errors['sites'] && !errors['sites'][i])
                                }
                                type={itemEditing !== i ? 'submit' : 'button'}
                            >
                                {itemEditing === i || (errors['sites'] && errors['sites'][i]) ? (
                                    <CheckRoundedIcon />
                                ) : (
                                    <EditRoundedIcon />
                                )}
                            </Button>
                        </div>
                    ))
                ) : (
                    <div>
                        <NoRowsOverlay />
                    </div>
                )}
            </div>
            {props.defaultValues.sites.length ? (
                <div className="mt-10">
                    <CustomPagination {...props.pagination} />
                </div>
            ) : null}
            <div className="flex-center ma-20">
                <ButtonRounded className="company-form-btn" types="outline" onClick={props.onClose}>
                    キャンセル
                </ButtonRounded>
            </div>
        </form>
    )
}
