import { SearchRounded } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import { CSSProperties, createStyles, makeStyles } from '@mui/styles'
import React from 'react'
import { SizeProps } from '../CustomModal'
import { fontSize } from '@mui/system'

export default function SearchBar(props: {
    value: string
    placeholder?: string
    setValue: React.Dispatch<React.SetStateAction<string>>
    size?: 'small' | 'medium'
    styles?: CSSProperties
    width?: SizeProps['size']
    height?: SizeProps['size']
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    autoComplete?: string
    fontSize?: number
}) {
    const useStyles = makeStyles(() =>
        createStyles({
            textInputRoot: {
                borderRadius: 5,
                width: props.width || 'auto',
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'center',
                '& > div': {
                    height: props.height || 'auto',
                },
            },
        }),
    )
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.setValue(e.target.value)

    const classes = useStyles()

    return (
        <div className="search-bar full-width">
            <TextField
                autoComplete={props.autoComplete || 'on'}
                size="small"
                variant="outlined"
                style={props.styles}
                classes={{ root: classes.textInputRoot }}
                id="input-with-icon-textfield"
                value={props.value}
                placeholder={props.placeholder || ''}
                onChange={props.onChange || handleChange}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchRounded />
                        </InputAdornment>
                    ),
                    style: { fontSize: props?.fontSize ? props.fontSize : 14 },
                }}
            />
        </div>
    )
}
